import styles from './index.module.scss'
import {useEffect, useState, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchModels, fetchTypes} from "/redux/action-creaters/models";
import clsx from "clsx";
import {formatType, getTypeUrl} from '../../../utils/index'
import {fetchSections} from "/redux/action-creaters/sections";
import Loader from "/components/common/loader"
import {useRouter} from "next/router";
import {Api} from "/api";
import {cacheFunctionData} from "/utils"
import Button from "/components/common/button"

export default function BaltexSelector() {
    const dispatch = useDispatch();
    const router = useRouter();

    const {marks} = useSelector(state => state.models)
    const [selectedMark, setSelectedMark] = useState(0);
    const [selectedModel, setSelectedModel] = useState(0);
    const [selectedType, setSelectedType] = useState(0);
    const [models, setModels] = useState([]);
    const [types, setTypes] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [commonSections, setCommonSections] = useState([]);
    const [loadState, setLoadState] = useState(true);
    const [itemLoading, setItemLoading] = useState(0);
    const {sections} = useSelector(state => state.sections)
    const [isLoading, setLoading] = useState(false);
    const [showAllMarks, setShowAllMarks] = useState(false);

    const marksBySymbol = useMemo(() => {
        const result = {};
        marks.map(mark => {
            if (!result[mark.NAME[0]]) {
                result[mark.NAME[0]] = []
            }
            result[mark.NAME[0]].push(mark);
        })
        return result
    }, [marks])

    const steps = {
        mark: 'mark',
        model: 'model',
        type: 'type',
        section: 'section'
    }

    const currentStep = useMemo(() => {
        if (!selectedMark) {
            return steps.mark
        }
        if (!selectedModel) {
            return steps.model
        }
        if (!selectedType) {
            return steps.type
        }
        return steps.section
    }, [selectedMark, selectedModel, selectedType]);


    const currentMark = useMemo(() => {
        return selectedMark && marks ? marks.find(item => item.ID == selectedMark) : null;
    }, [selectedMark, marks])

    const currentModel = useMemo(() => {
        return selectedModel && models ? models.find(item => item.ID == selectedModel) : null;
    }, [selectedModel, models])

    const currentType = useMemo(() => {
        return selectedType && types ? types.find(item => item.ID == selectedType) : null;
    }, [selectedType, types])

    let currentSections = [];

    const selectItem = (id) => {
        const sectionUrl = sections[id]['SECTION_PAGE_URL'];
        const url = getTypeUrl(sectionUrl, currentMark['CODE'], currentModel['CODE'], currentType['CODE']);
        router.push(url);
    }

    const resetAll = () => {
        setSelectedMark(0);
        setSelectedModel(0);
        setSelectedType(0);
    }

    useEffect(async () => {
        if (typeof localStorage === 'object') {
            setLoading(true)
            const mark = localStorage.getItem('mark')
            if (mark > 0) {
                setSelectedMark(mark);
            }

            const model = localStorage.getItem('model')
            if (model > 0) {
                setSelectedModel(model);
            }

            const type = localStorage.getItem('type')
            if (type > 0) {
                setSelectedType(type);
            }

            setTimeout(() => {
                setLoadState(false)
                setLoading(false)
            });
        }

    }, [])

    useEffect(async () => {

        const selector = document.getElementById('selector');
        if (window.scrollY > selector.offsetTop) {
            window.scrollTo({top: selector.offsetTop - 80, behavior: 'smooth'})
        }

        if (typeof localStorage === 'object') {
            localStorage.setItem('mark', selectedMark);
        }
        if (selectedMark == 0) {
            return
        }
        setLoading(true)

        setModels(await Api.models.getModelsByMarkIdBaltex(selectedMark, 'ID,NAME,CODE'))
        //await dispatch(fetchModels(selectedMark, 'ID,NAME,CODE'))

        if (!loadState) {
            setSelectedModel(0);
            setSelectedType(0);
            setLoading(false);
        }


    }, [selectedMark]);

    useEffect(() => {
        if (models.length === 1) {
            setSelectedModel(models[0].ID);
        }
    }, [models]);

    useEffect(async () => {
        if (typeof localStorage === 'object') {
            localStorage.setItem('model', selectedModel);
        }
        if (selectedModel == 0) {
            return
        }
        setLoading(true)

        setTypes(await Api.models.getTypesByModelIdBaltex(selectedModel, 'ID,NAME,SECTIONS,CODE'));
       // await dispatch(fetchTypes(selectedModel, 'ID,NAME,SECTIONS,CODE'))

        if (!loadState) {
            setSelectedType(0);
            setLoading(false);
        }
    }, [selectedModel]);

    useEffect(() => {
        if (types.length === 1) {
            setSelectedType(types[0].ID);
        }
    }, [types]);

    useEffect(async () => {
        if (selectedType) {
            if (commonSections.length === 0) {
                const data = await cacheFunctionData(Api.models.getCommonSections, 'commonSections')
                setCommonSections(JSON.parse(data))
            }
        }
    }, [selectedType, commonSections]);

    useEffect(async () => {
        if (selectedType) {
            if (sections.length === 0) {
                await dispatch(await fetchSections())
            }
        }
    }, [selectedType, sections]);

    useEffect(async () => {
        if (typeof localStorage === 'object') {
            localStorage.setItem('type', selectedType);
        }
        if (selectedType && types) {
            const type = types.find(item => item.ID == selectedType);
            const typeSections = type ? JSON.parse(type?.SECTIONS) : [];
            const commonSectionsWithOutExits = {...commonSections}
            for (let id in commonSections) {
                let cnt = +commonSections[id];
                if (typeSections[id]) {
                    typeSections[id] = +typeSections[id] + cnt;
                    delete commonSectionsWithOutExits[id];
                }
            }
            const selectedSections = [];
            for (let id in typeSections) {
                if (sections[id]) {
                    selectedSections.push({
                        NAME: sections[id].NAME,
                        COUNT: typeSections[id],
                        ID: id,
                        SORT: sections[id].UF_SORT_FILTER ?? sections[id].SORT,
                    });
                }
            }
            const selectedCommonSections = [];
            for (let id in commonSectionsWithOutExits) {
                if (sections[id]) {
                    selectedCommonSections.push({
                        NAME: sections[id].NAME,
                        COUNT: commonSectionsWithOutExits[id],
                        ID: id,
                        SORT: sections[id].UF_SORT_FILTER ?? sections[id].SORT,
                    });
                }
            }
    
            selectedSections.sort((a, b) => parseInt(a.SORT) - parseInt(b.SORT))
            setSelectedSections(selectedSections);

            if (typeof localStorage === 'object') {
                localStorage.setItem('filterUrl', `/filter/marka-${currentMark?.CODE}/model-${currentModel?.CODE}/kuzov-${currentType?.CODE}`)
            }

        }
    }, [selectedType, sections, commonSections, types]);

    const isMobile = useMemo(() => {
        if (typeof window === 'undefined') {
            return false
        }
        return window.innerWidth < 991
    })

    return (
        <div className={styles.Selector} id="selector">
            <div className={styles.Top} data-type={currentStep}>
                <h2>Выберите ваш автомобиль</h2>
                <select
                    className={styles.Select}
                    value={selectedMark}
                    onChange={event => setSelectedMark(event.target.value)}
                    data-value={selectedMark}
                    >
                    <option value='0' disabled>Выберите марку</option>
                    {marks && marks.map(mark => (
                        <option key={mark.ID} value={mark.ID}>{ mark.NAME }</option>
                    ))}
                </select>
                {selectedMark > 0 && (
                    <select
                        className={styles.Select}
                        value={selectedModel}
                        onChange={event => setSelectedModel(event.target.value)}
                        data-value={selectedModel}
                        >
                        <option value='0' disabled>Выберите модель</option>
                        {models && models?.map(model => (
                            <option key={model.ID} value={model.ID}>{ model.NAME }</option>
                        ))}
                    </select>
                )}
                {selectedModel > 0 && (
                    <select
                        className={styles.Select}
                        value={selectedType}
                        onChange={event => setSelectedType(event.target.value)}
                        data-value={selectedType}
                        >
                        <option value='0' disabled>Выберите кузов</option>
                        {types && types?.map(type => (
                            <option key={type.ID} value={type.ID}>{ type.NAME }</option>
                        ))}
                    </select>
                )}
                <div className={styles.Reset} onClick={resetAll}>Сбросить все</div>
            </div>
            {isLoading && (
                <div className={styles.Loader}>
                    <Loader/>
                </div>
            )}
            {showAllMarks && currentStep === steps.mark && !isMobile && (
                <div className={styles.Symbols}>
                    {Object.keys(marksBySymbol).map(symbol => (
                        <div className={styles.Symbol} key={symbol}>
                            <span>{symbol}</span>
                            <ul className={styles.Marks}>
                                {marksBySymbol[symbol].map(mark => (
                                    <li key={mark.ID} onClick={() => setSelectedMark(mark.ID)}>
                                        <img src={mark.PICTURE_SRC}/>
                                        <span>{mark.NAME}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
            {!showAllMarks && currentStep === steps.mark && !isMobile && (
                <div>
                    <ul className={styles.TopMarks}>
                        {marks.filter(mark => mark.TOP).map(mark => (
                            <li key={mark.ID} onClick={() => setSelectedMark(mark.ID)}>
                                <span><img src={mark.PICTURE_SRC}/></span>
                            </li>
                        ))}
                    </ul>
                    <ul className={clsx(styles.Items,styles.ItemsMarks)}>
                        {marks.filter(mark => mark.RECOMENDED).map(mark => (
                            <li key={mark.ID} onClick={() => setSelectedMark(mark.ID)}>
                                <span>{mark.NAME}</span>
                            </li>
                        ))}
                    </ul>
                    <Button
                        label={'Показать все марки'}
                        type={'none'}
                        onClick={() => {
                            setShowAllMarks(true)
                        }}
                        after={
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M5.29297 9.70712L6.70718 8.29291L12.0001 13.5858L17.293 8.29291L18.7072 9.70712L12.0001 16.4142L5.29297 9.70712Z"
                                      fill="#191919"/>
                            </svg>
                        }
                    />
                </div>
            )}
            {currentStep === steps.model && !isMobile && (
                <ul className={styles.Items}>
                    {models.map(item => (
                        <li key={item.ID} onClick={() => setSelectedModel(item.ID)}>
                            <span>{item.NAME}</span>
                        </li>
                    ))}
                </ul>
            )}
            {currentStep === steps.type && !isMobile && (
                <ul className={clsx(styles.Items,styles.TypesItems)}>
                    {types.map(item => (
                        <li key={item.ID} onClick={() => setSelectedType(item.ID)}>
                            <span>{formatType(item.NAME)}</span>
                            {item.GEN && (<div className={styles.Gen}>{item.GEN}</div>)}
                        </li>
                    ))}
                </ul>
            )}
            {currentStep === steps.section && (
                <>
                    <div className={styles.SectionsTitle}>Товары для {currentMark?.NAME} {currentModel?.NAME} {currentType?.NAME}</div>
                    <ul className={styles.SectionsItems}>
                        {selectedSections.map(item => (
                            <li key={item.ID} onClick={() => selectItem(item.ID)}>
                                <span>{item.NAME}</span>
                                <div className={styles.Count}>{/*item.COUNT*/}</div>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    )
}