import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {fetchShops} from "../../../redux/action-creaters/location";
import clsx from "clsx";
import Button from "../../common/button";
import {showPopup} from "../../../redux/action-creaters/popup";
import {popupTypes} from "../../../redux/reducers/popupReducer";

export default function Shops({rows = 4, title='Все магазины и установочные центры', isBaltex, isFarkop = true, host = '', isShowShopsForRegions = true}) {
    const dispatch = useDispatch();
    const {shops} = useSelector(state => state.location)
    const {contacts} = useSelector(state => state.contacts)
    const {
        cityName,
    } = useSelector(state => state.location)

    const shopsFiltered = useMemo(() => {
        return Object.values(shops).filter(({MEDIA_SRC, TYPE}) => TYPE && MEDIA_SRC?.length > 0).map(shop => {
            let call_touch;
            if (contacts && Array.isArray(contacts)) {
                contacts.map(contact => {
                    if (shop.NAME.indexOf(contact.NAME) > -1) {
                        call_touch = contact.CALL_TOUCH_CODE
                    }
                })
            }
            return {
                call_touch,
                ...shop
            }
        })
    }, [shops, contacts])
    
    const shopsFilteredHost = useMemo(() => {
        if (host) {
            return shopsFiltered.filter(shop => {
                if (host === 'localhost:3000') {
                    return true;
                }
                
                if (host === 'farkop.ru' || host === 'demo.farkop.ru' || host === 'farkop-msk.ru') {
                    return shop['NAME'].includes('Москва');
                }
                if (host === 'spb.farkop.ru' || host === 'spb.farkop-msk.ru') {
                    return shop['NAME'].includes('Санкт-Петербург');
                }
                return isShowShopsForRegions;
            });
        }
        return shopsFiltered
    }, [host, shopsFiltered]);

    return (
        <div>
            {title && <h2>{title}</h2>}
            <div className={clsx(styles.ShopsList, styles[`ShopsListRow_${rows}`], isBaltex && styles.ShopsListBaltex)}>
                {shopsFilteredHost.length === 0 && (
                    <span className={styles.NoShops}>Мы работаем над открытием сервисного центра в г.{cityName}, можем предложить отправку товара в Ваш город</span>
                )}
                {shopsFilteredHost.map(shop => (
                    <a
                        key={shop.ID}
                        href={isFarkop ? `/shops/${shop.ID}/` : ''}
                        className={styles.ShopsItem}
                    >
                        <div>
                            <span className={styles.Name}>{shop.NAME}</span>
                            <div className={clsx(styles.Phone, shop.call_touch)}>{shop.PHONE}</div>
                            <div className={styles.Time}>{shop.WORK_TIME}</div>
                            <div className={styles.Types}>
                                {shop.TYPE.map(type => (
                                    <span key={type}>{type}</span>
                                ))}
                            </div>
                        </div>

                        {shop.TYPE.includes('Установочный центр') && (
                            <Button type='outline' label="Заказать установку" onClick={function (e) {
                                dispatch(showPopup(popupTypes.orderInstall, shop.NAME))
                            }}/>
                        )}
                        <div className={styles.Bottom}>
                            <div
                                className={styles.FastView}
                                onClick={function (e) {
                                    e.nativeEvent.preventDefault()
                                    e.stopPropagation()
                                    dispatch(showPopup(popupTypes.fastViewService, shop.ID))
                                }}
                            >
                                <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 14C8.35987 14.0204 6.7367 13.6664 5.254 12.965C4.10469 12.4042 3.07265 11.6297 2.213 10.683C1.30243 9.7041 0.585467 8.56164 0.1 7.31598L0 6.99998L0.105 6.68398C0.590815 5.43941 1.30624 4.29725 2.214 3.31698C3.07334 2.37029 4.10504 1.59584 5.254 1.03498C6.73671 0.333567 8.35988 -0.0204101 10 -2.11214e-05C11.6401 -0.0203749 13.2633 0.333601 14.746 1.03498C15.8953 1.59571 16.9274 2.37017 17.787 3.31698C18.6993 4.29453 19.4165 5.43734 19.9 6.68398L20 6.99998L19.895 7.31598C18.3262 11.3998 14.3742 14.0693 10 14ZM10 1.99998C6.59587 1.89331 3.47142 3.87507 2.117 6.99998C3.4712 10.1251 6.59579 12.1069 10 12C13.4041 12.1064 16.5284 10.1247 17.883 6.99998C16.5304 3.87356 13.4047 1.89106 10 1.99998ZM10 9.99998C8.55733 10.0095 7.30937 8.99734 7.02097 7.58375C6.73256 6.17017 7.48427 4.75 8.81538 4.19364C10.1465 3.63728 11.6852 4.10011 12.4885 5.29849C13.2919 6.49686 13.1354 8.09606 12.115 9.11598C11.5563 9.68124 10.7948 9.99954 10 9.99998Z" fill="#1247A5"/>
                                </svg>Быстрый просмотр</div>
                            {shop.DRIVE_SCHEME && <div
                                className={styles.Scheme}
                                onClick={function (e) {
                                    e.nativeEvent.preventDefault()
                                    e.stopPropagation()
                                    dispatch(showPopup(popupTypes.scheme, shop.ID))
                                }}
                            >

                                <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.108 21.092L0 18.721V2.61303L6.892 4.91303L13.923 0.892029L20 3.32303V19.477L14.077 17.107L7.109 21.092H7.108ZM2 5.39203V17.279L6 18.612V6.72003L2 5.39203ZM12 4.29203L8 6.58003V18.28L12 15.992V4.29203ZM14.077 3.10603L14 3.15203V14.923L18 16.523V4.67603L14.077 3.10603Z" fill="#1247A5"/>
                                </svg>

                                Схема проезда</div>}
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}